<template>
  <div class="settings-box white-box">
    <div class="settings-box__form">
      <h3 class="tab-heading">
        {{ $t('profile.appearance') }}
      </h3>
      <form
        ref="form"
        data-vv-scope="appear"
        class="form-horizontal"
        @submit.prevent="submitForm('appear')"
      >
        <div class="form-group">
          <div class="row">
            <label for="hair" class="form-group--label col-sm-2"
              >{{ $t('profile.hairColor') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                id="hair"
                v-model="selected.hair_color"
                :allow-empty="false"
                :options="values.hair"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label for="eyes" class="form-group--label col-sm-2"
              >{{ $t('profile.eyesColor') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                id="eyes"
                v-model="selected.eye_color"
                :allow-empty="false"
                :options="values.eyes"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label for="bd" class="form-group--label col-sm-2">{{ $t('profile.bodyType') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                id="bd"
                v-model="selected.body"
                :allow-empty="false"
                :options="values.body"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': validateHeight }">
          <div class="row">
            <label for="h-1" class="form-group--label col-sm-2">{{ $t('profile.height') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <div class="set-flex multiple-row">
                <div class="appearance-height">
                  <multiselect
                    id="h-1"
                    v-model="height.feet"
                    :allow-empty="false"
                    :options="values.feet"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
                <div class="appearance-height">
                  <multiselect
                    v-model="height.inches"
                    :allow-empty="false"
                    :options="values.inches"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
              </div>
              <div v-show="validateHeight" class="error">
                <i class="fa fa-warning" />
                <span>{{ $t('profile.errors.failHeight') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.first('appear.weight') }">
          <div class="row">
            <label for="weight" class="form-group--label col-sm-2"
              >{{ $t('profile.weight') }}:</label
            >
            <div class="form-group--input col-sm-10">
              <div class="input-group">
                <input
                  id="weight"
                  v-model="weight"
                  v-validate="'min_value:0|numeric'"
                  type="number"
                  name="weight"
                  data-vv-as="Weight"
                  class="form-control transparent w-full"
                />
                <div class="input-group-addon">
                  {{ $t('profile.lbs') }}
                </div>
              </div>
              <div v-show="errors.has('appear.weight')" class="error">
                <i class="fa fa-warning is-danger" />
                <span class="is-danger">{{ errors.first('appear.weight') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2" />
          <div class="col-sm-10">
            <rb-button class="btn-black" type="submit">
              {{ $t('profile.save') }}
            </rb-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { MEASURE_CALCULATIONS } from '../../../../mixins/types/profile';
import RbButton from '../../../Button';

export default {
  components: {
    RbButton,
  },
  mixins: [MEASURE_CALCULATIONS],
  data() {
    return {
      utils: {
        merged: false,
      },
      values: {
        feet: [
          { k: 'no', v: this.$t('profile.vFeet.no') },
          { k: 0, v: this.$t('profile.vFeet.v', ['0']) },
          { k: 4, v: this.$t('profile.vFeet.v', ['4']) },
          { k: 5, v: this.$t('profile.vFeet.v', ['5']) },
          { k: 6, v: this.$t('profile.vFeet.v', ['6']) },
        ],
        inches: [
          { k: 'no', v: this.$t('profile.vInches.no') },
          { k: 0, v: this.$t('profile.vInches.v', ['0']) },
          { k: 1, v: this.$t('profile.vInches.v', ['1']) },
          { k: 2, v: this.$t('profile.vInches.v', ['2']) },
          { k: 3, v: this.$t('profile.vInches.v', ['3']) },
          { k: 4, v: this.$t('profile.vInches.v', ['4']) },
          { k: 5, v: this.$t('profile.vInches.v', ['5']) },
          { k: 6, v: this.$t('profile.vInches.v', ['6']) },
          { k: 7, v: this.$t('profile.vInches.v', ['7']) },
          { k: 8, v: this.$t('profile.vInches.v', ['8']) },
          { k: 9, v: this.$t('profile.vInches.v', ['9']) },
          { k: 10, v: this.$t('profile.vInches.v', ['10']) },
          { k: 11, v: this.$t('profile.vInches.v', ['11']) },
        ],
        eyes: [
          { k: 'no', v: this.$t('profile.vEyes.no') },
          { k: 'black', v: this.$t('profile.vEyes.black') },
          { k: 'green', v: this.$t('profile.vEyes.green') },
          { k: 'gray', v: this.$t('profile.vEyes.gray') },
          { k: 'brown', v: this.$t('profile.vEyes.brown') },
          { k: 'light-brown', v: this.$t('profile.vEyes.lightBrown') },
          { k: 'blue', v: this.$t('profile.vEyes.blue') },
        ],
        hair: [
          { k: 'no', v: this.$t('profile.vHair.no') },
          { k: 'blonde', v: this.$t('profile.vHair.blond') },
          { k: 'brown', v: this.$t('profile.vHair.brown') },
          { k: 'redhead', v: this.$t('profile.vHair.redhead') },
          { k: 'brunette', v: this.$t('profile.vHair.brunette') },
          { k: 'colored', v: this.$t('profile.vHair.colored') },
        ],
        body: [
          { k: 'no', v: this.$t('profile.vBody.no') },
          { k: 'thin', v: this.$t('profile.vBody.thin') },
          { k: 'slender', v: this.$t('profile.vBody.slender') },
          { k: 'athletic', v: this.$t('profile.vBody.athletic') },
          { k: 'a few extra pounds', v: this.$t('profile.vBody.extra') },
          { k: 'plump', v: this.$t('profile.vBody.plump') },
        ],
      },
      selected: {
        eye_color: '',
        hair_color: '',
        body: '',
        weight: 0,
        height: 0,
      },
      height: {
        feet: 0,
        inches: 0,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    //			values() {
    //
    //			},
    /**
     * получить/записать вес с конвертацией в кг/фунты соответственнло
     *
     * @return {number}
     */
    weight: {
      get() {
        return Math.round(this.kgToLbs(this.selected.weight));
      },
      set(v) {
        this.selected.weight = this.lbsToKg(v);
      },
    },
    feet() {
      return this.height.feet;
    },
    inches() {
      return this.height.inches;
    },
    /**
     * вализация роста
     *
     * @return {boolean}
     */
    validateHeight() {
      return (
        (this.height.inches === 'no' && this.feet !== 'no') ||
        (this.feet === 'no' && this.inches !== 'no')
      );
    },
  },
  watch: {
    /**
     * запуск получения данных после получения данных о своем профиле
     */
    /*      user() {
        this.initParams();
      }, */
    /**
     * очистка параметра дюйма при очистке фута
     *
     * @param v {string}
     */
    feet(v) {
      if (v === 'no') this.height.inches = 'no';
    },
    /**
     * очистка параметра фута при очистке дюйма
     *
     * @param v {string}
     */
    inches(v) {
      if (v === 'no') this.height.feet = 'no';
    },
  },
  mounted() {
    document.title = this.$t('profile.appearance');
    setTimeout(() => {
      this.initParams();
      this.$forceUpdate();
    }, 300);
  },
  methods: {
    /**
     * провалидировать форму и, если всё ок, то послать данные на сервер
     * в паротивном случае поднять скролл наверх
     *
     * @param scope
     */
    submitForm(scope) {
      this.$validator
        .validateAll(scope)
        .then((response) => {
          if (response && !this.validateHeight) {
            this.sendData();
          } else {
            this.$scrollTo(this.$refs.form, 1000, { offset: -50 });
          }
        })
        .catch(() => this.$scrollTo(this.$refs.form, 1000, { offset: -50 }));
    },
    /**
     * отправка данных на сервер
     */
    sendData() {
      this.$http
        .post('v1/profile/edit-appearance', {
          access_token: window.localStorage['access-token'],
          eye_color: this.selected.eye_color ? this.selected.eye_color.k : '',
          hair_color: this.selected.hair_color ? this.selected.hair_color.k : '',
          body: this.selected.body ? this.selected.body.k : '',
          weight: this.selected.weight,
          height:
            this.feet === 'no' ? 0 : this.feetInchToCm(this.height.feet.k, this.height.inches.k),
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$store.commit('addAlert', {
                type: 'success',
                text: this.$t('alerts.settingsSuccess'),
              });
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
          }
        );
      this.updateProfile();
    },
    /**
     * обновить свой профиль
     */
    updateProfile() {
      this.$store.commit('updateUserProfile', {
        profile_additional_info: {
          eye_color: this.selected.eye_color,
          hair_color: this.selected.hair_color ? this.selected.hair_color.k : '',
          body: this.selected.body,
          weight: this.selected.weight,
          height: this.feet === 'no' ? 0 : this.feetInchToCm(this.height.feet, this.height.inches),
        },
      });
    },
    /**
     * записать данные с инфой о профиле из хранилища в компонент
     */
    initParams() {
      if (!this.utils.merged) {
        for (const key in this.selected) {
          this.selected[key] = this.user.profile_additional_info[key];
        }

        if (this.selected.height === 0 || this.selected.height === null) {
          this.height = {
            feet: 'no',
            inches: 'no',
          };
        } else {
          this.height = {
            feet: this.cmToFeetInch(this.selected.height).feet,
            inches: this.cmToFeetInch(this.selected.height).inches,
          };
        }
        this.utils.merged = true;
      }
      this.selected.hair_color = this.values.hair.find((i) => i.k === this.selected.hair_color);
      this.selected.eye_color = this.values.eyes.find((i) => i.k === this.selected.eye_color);
      this.selected.body = this.values.body.find((i) => i.k === this.selected.body);
      this.height.feet = this.values.feet.find(
        (i) => i.k === this.cmToFeetInch(this.selected.height).feet
      );
      this.height.inches = this.values.inches.find(
        (i) => i.k === this.cmToFeetInch(this.selected.height).inches
      );
    },
  },
};
</script>
