<template>
  <div class="settings-box white-box">
    <div class="settings-box__form">
      <h2 class="tab-heading">
        {{ $t('profile.aboutMe') }}
      </h2>
      <form
        ref="form"
        class="form-horizontal"
        data-vv-scope="ed-ab"
        @submit.prevent="submitForm('ed-ab')"
      >
        <div class="form-group">
          <div class="row">
            <label for="rel" class="form-group--label col-sm-2"
              >{{ $t('profile.religion') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                id="rel"
                v-model="selected.religion"
                :allow-empty="false"
                :options="values.religion"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.first('ed-ab.occup') }">
          <div class="row">
            <label for="occ" class="form-group--label col-sm-2"
              >{{ $t('profile.occupation') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <input
                id="occ"
                v-model="selected.profession"
                v-validate="'max:100'"
                type="text"
                name="occup"
                data-vv-as="Occupation"
                :placeholder="$t('profile.occupation')"
                class="form-control transparent w-full"
              />
              <div v-show="errors.has('ed-ab.occup')" class="error">
                <i class="fa fa-warning is-danger" />
                <span class="is-danger">{{ errors.first('ed-ab.occup') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2">{{ $t('profile.goals') }}:</label>
            <div class="form-group--input col-sm-10">
              <p
                class="form-control transparent w-full form-control-static toggle"
                @click="utils.showGoals = !utils.showGoals"
              >
                <span class="toggle__text text-muted">{{
                  utils.showGoals ? $t('profile.options.hide') : $t('profile.options.show')
                }}</span>
              </p>
              <div v-show="utils.showGoals" class="form-group--checkboxes-box row">
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="friendship" v-model="selected.target_friendship">
                    {{ $t('profile.vGoals.friendship') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="communication" v-model="selected.target_communication">
                    {{ $t('profile.vGoals.communication') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="journey" v-model="selected.target_journey">
                    {{ $t('profile.vGoals.journey') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="flirtation" v-model="selected.target_flirtation">
                    {{ $t('profile.vGoals.flirt') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="meeting" v-model="selected.target_meeting">
                    {{ $t('profile.vGoals.meeting') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="family" v-model="selected.target_family">
                    {{ $t('profile.vGoals.family') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="children" v-model="selected.target_children">
                    {{ $t('profile.vGoals.children') }}
                  </custom-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label for="educ" class="form-group--label col-sm-2"
              >{{ $t('profile.educations') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                id="educ"
                v-model="selected.education"
                :allow-empty="false"
                :options="values.education"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label for="smok" class="form-group--label col-sm-2">{{ $t('profile.smoke') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                id="smok"
                v-model="selected.smoke"
                :allow-empty="false"
                :options="values.smoking"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label for="alco" class="form-group--label col-sm-2"
              >{{ $t('profile.alcohol') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                id="alco"
                v-model="selected.drink"
                :allow-empty="false"
                :options="values.alcohol"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2">{{ $t('profile.holidays') }}:</label>
            <div class="form-group--input col-sm-10">
              <p
                class="form-control transparent w-full-static toggle"
                @click="utils.showHolidays = !utils.showHolidays"
              >
                <span class="toggle__text text-muted">{{
                  utils.showHolidays ? $t('profile.options.hide') : $t('profile.options.show')
                }}</span>
              </p>
              <div v-show="utils.showHolidays" class="form-group--checkboxes-box row">
                <div class="col-md-5 col-xs-6">
                  <custom-checkbox id="birth" v-model="selected.celebration_birthday">
                    {{ $t('profile.vHolidays.birthday') }}
                  </custom-checkbox>
                </div>
                <div class="col-md-5 col-xs-6">
                  <custom-checkbox id="christmas" v-model="selected.celebration_christmas_day">
                    {{ $t('profile.vHolidays.christmas') }}
                  </custom-checkbox>
                </div>
                <div class="col-md-5 col-xs-6">
                  <custom-checkbox id="ny" v-model="selected.celebration_new_years_day">
                    {{ $t('profile.vHolidays.newYear') }}
                  </custom-checkbox>
                </div>
                <div class="col-md-5 col-xs-6">
                  <custom-checkbox id="easter" v-model="selected.celebration_easter">
                    {{ $t('profile.vHolidays.easter') }}
                  </custom-checkbox>
                </div>
                <div class="col-md-5 col-xs-6">
                  <custom-checkbox id="vd" v-model="selected.celebration_valentines_day">
                    {{ $t('profile.vHolidays.valentine') }}
                  </custom-checkbox>
                </div>
                <div class="col-md-5 col-xs-6">
                  <custom-checkbox id="hl" v-model="selected.celebration_halloween">
                    {{ $t('profile.vHolidays.halloween') }}
                  </custom-checkbox>
                </div>
                <div class="col-md-5 col-xs-6">
                  <custom-checkbox id="wd" v-model="selected.celebration_womens_day">
                    {{ $t('profile.vHolidays.womensDay') }}
                  </custom-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label for="child" class="form-group--label col-sm-2"
              >{{ $t('profile.children') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                id="child"
                v-model="selected.children"
                :allow-empty="false"
                :options="values.children"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.first('ed-ab.int') }">
          <div class="row">
            <label for="int" class="form-group--label col-sm-2">{{ $t('profile.hobbies') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <input
                id="int"
                v-model="selected.interests"
                v-validate="'max:160'"
                type="text"
                name="int"
                class="form-control transparent w-full"
                data-vv-as="Interests"
                :placeholder="$t('profile.hobbies')"
              />
              <div v-show="errors.has('ed-ab.int')" class="error">
                <i class="fa fa-warning is-danger" />
                <span class="is-danger">{{ errors.first('ed-ab.int') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2">{{ $t('profile.pet') }}:</label>
            <div class="form-group--input col-sm-10">
              <p
                class="form-control transparent w-full-static toggle"
                @click="utils.showPets = !utils.showPets"
              >
                <span class="toggle__text text-muted">{{
                  utils.showPets ? $t('profile.options.hide') : $t('profile.options.show')
                }}</span>
              </p>
              <div v-show="utils.showPets" class="form-group--checkboxes-box row">
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="dog" v-model="selected.pet_dog">
                    {{ $t('profile.vPets.dog') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="cat" v-model="selected.pet_cat">
                    {{ $t('profile.vPets.cat') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="hamster" v-model="selected.pet_hamster">
                    {{ $t('profile.vPets.hamster') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="rabbit" v-model="selected.pet_rabbit">
                    {{ $t('profile.vPets.rabbit') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="aquarium fish" v-model="selected.pet_aquarium_fish">
                    {{ $t('profile.vPets.fish') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="parrot" v-model="selected.pet_parrot">
                    {{ $t('profile.vPets.parrot') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="turtle" v-model="selected.pet_turtle">
                    {{ $t('profile.vPets.turtle') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="snake" v-model="selected.pet_snake">
                    {{ $t('profile.vPets.snake') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="iguana" v-model="selected.pet_iguana">
                    {{ $t('profile.vPets.iguana') }}
                  </custom-checkbox>
                </div>
                <div class="col-sm-4 col-xs-6">
                  <custom-checkbox id="chinchilla" v-model="selected.pet_chinchilla">
                    {{ $t('profile.vPets.chinchilla') }}
                  </custom-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label for="lv" class="form-group--label col-sm-2">{{ $t('profile.iLive') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                id="lv"
                v-model="selected.accommodation"
                :allow-empty="false"
                :options="values.accommodation"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.first('ed-ab.about') }">
          <div class="row">
            <label for="about" class="form-group--label col-sm-2"
              >{{ $t('profile.aboutMe') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <textarea
                id="about"
                v-model="selected.about_me"
                v-validate="'max:1000'"
                name="about"
                data-vv-as="About me"
                class="form-control transparent w-full"
              />
              <div v-show="errors.has('ed-ab.about')" class="error">
                <i class="fa fa-warning is-danger" />
                <span class="is-danger">{{ errors.first('ed-ab.about') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2" />
          <div class="col-sm-10">
            <rb-button class="btn-black" type="submit">
              {{ $t('profile.save') }}
            </rb-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import customCheckbox from '../../parts/CustomCheckbox';
import RbButton from '../../../Button';

export default {
  components: {
    customCheckbox,
    RbButton,
  },
  data() {
    return {
      utils: {
        showGoals: false,
        showHolidays: false,
        showPets: false,
        merged: false,
      },
      values: {
        religion: [
          { k: 'no', v: this.$t('profile.vReligions.no') },
          { k: 'Christianity', v: this.$t('profile.vReligions.christianity') },
          { k: 'Catholicism', v: this.$t('profile.vReligions.catholicism') },
          { k: 'Buddhism', v: this.$t('profile.vReligions.buddhism') },
          { k: 'Hinduism', v: this.$t('profile.vReligions.hinduism') },
          { k: 'Islam', v: this.$t('profile.vReligions.islam') },
          { k: 'other', v: this.$t('profile.vReligions.other') },
        ],
        education: [
          { k: 'no', v: this.$t('profile.vEducation.no') },
          { k: 'Middle School', v: this.$t('profile.vEducation.mSchool') },
          { k: 'High school', v: this.$t('profile.vEducation.hSchool') },
          { k: 'Student', v: this.$t('profile.vEducation.student') },
          { k: "Bachelor's Degree", v: this.$t('profile.vEducation.bachelor') },
          { k: "Master's Degree", v: this.$t('profile.vEducation.master') },
          { k: 'Doctoral Level', v: this.$t('profile.vEducation.doctor') },
        ],
        smoking: [
          { k: 'no', v: this.$t('profile.vSmoking.no') },
          { k: 'I do not smoke', v: this.$t('profile.vSmoking.not') },
          { k: 'rarely', v: this.$t('profile.vSmoking.rarely') },
          { k: 'often', v: this.$t('profile.vSmoking.often') },
        ],
        alcohol: [
          { k: 'no', v: this.$t('profile.vAlcohol.no') },
          { k: 'I do not drink', v: this.$t('profile.vAlcohol.not') },
          { k: 'rarely', v: this.$t('profile.vAlcohol.rarely') },
          { k: 'often', v: this.$t('profile.vAlcohol.often') },
          { k: 'occasionally', v: this.$t('profile.vAlcohol.occasionally') },
        ],
        children: [
          { k: 'no', v: this.$t('profile.vChildren.no') },
          { k: 'I have no children', v: this.$t('profile.vChildren.myNot') },
          { k: 'no but I want to have children', v: this.$t('profile.vChildren.want') },
          { k: 'have children', v: this.$t('profile.vChildren.have') },
          { k: 'have children, but living separately', v: this.$t('profile.vChildren.haveSep') },
          { k: 'have adult children', v: this.$t('profile.vChildren.adult') },
        ],
        accommodation: [
          { k: 'no', v: this.$t('profile.vAccommodation.no') },
          { k: 'with parents', v: this.$t('profile.vAccommodation.parents') },
          { k: 'with neighbors', v: this.$t('profile.vAccommodation.neighbors') },
          { k: 'in dorm', v: this.$t('profile.vAccommodation.dorm') },
          { k: 'separately (rented housing)', v: this.$t('profile.vAccommodation.rent') },
          { k: 'separately (in own housing)', v: this.$t('profile.vAccommodation.own') },
        ],
      },
      selected: {
        religion: { k: 'no', v: this.$t('profile.vReligions.no') },
        profession: '',
        education: '',
        smoke: '',
        drink: '',
        children: '',
        interests: '',
        pet_aquarium_fish: false,
        pet_cat: false,
        pet_chinchilla: false,
        pet_dog: false,
        pet_hamster: false,
        pet_iguana: false,
        pet_parrot: false,
        pet_snake: false,
        pet_turtle: false,
        pet_rabbit: false,
        celebration_birthday: false,
        celebration_christmas_day: false,
        celebration_easter: false,
        celebration_halloween: false,
        celebration_new_years_day: false,
        celebration_valentines_day: false,
        celebration_womens_day: false,
        target_family: false,
        target_meeting: false,
        target_children: false,
        target_communication: false,
        target_flirtation: false,
        target_friendship: false,
        target_journey: false,
        accommodation: '',
        about_me: '',
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    /**
     * при загрузке своего профиля спрашивает данные о профиле
     *
     * @param v
     */
    /*			user( v ) {
                      this.initParams();
                  } */
  },
  mounted() {
    document.title = this.$t('profile.aboutMe');
    setTimeout(() => {
      this.initParams();
      this.$forceUpdate();
    }, 300);
  },
  methods: {
    /**
     * загрузить данные своего профиля
     */
    initParams() {
      if (!this.utils.merged) {
        Object.assign(this.selected, this.user.profile_additional_info);
        this.utils.merged = true;
      }
      this.selected.religion = this.values.religion.find((i) => i.k === this.selected.religion);
      this.selected.education = this.values.education.find((i) => i.k === this.selected.education);
      this.selected.smoke = this.values.smoking.find((i) => i.k === this.selected.smoke);
      this.selected.drink = this.values.alcohol.find((i) => i.k === this.selected.drink);
      this.selected.children = this.values.children.find((i) => i.k === this.selected.children);
      this.selected.accommodation = this.values.accommodation.find(
        (i) => i.k === this.selected.accommodation
      );

      this.selected.target_family = !!this.selected.target_family;
      this.selected.target_meeting = !!this.selected.target_meeting;
      this.selected.target_children = !!this.selected.target_children;
      this.selected.target_communication = !!this.selected.target_communication;
      this.selected.target_flirtation = !!this.selected.target_flirtation;
      this.selected.target_friendship = !!this.selected.target_friendship;
      this.selected.target_journey = !!this.selected.target_journey;

      this.selected.celebration_birthday = !!this.selected.celebration_birthday;
      this.selected.celebration_christmas_day = !!this.selected.celebration_christmas_day;
      this.selected.celebration_easter = !!this.selected.celebration_easter;
      this.selected.celebration_halloween = !!this.selected.celebration_halloween;
      this.selected.celebration_new_years_day = !!this.selected.celebration_new_years_day;
      this.selected.celebration_valentines_day = !!this.selected.celebration_valentines_day;
      this.selected.celebration_womens_day = !!this.selected.celebration_womens_day;

      this.selected.pet_aquarium_fish = !!this.selected.pet_aquarium_fish;
      this.selected.pet_cat = !!this.selected.pet_cat;
      this.selected.pet_chinchilla = !!this.selected.pet_chinchilla;
      this.selected.pet_dog = !!this.selected.pet_dog;
      this.selected.pet_hamster = !!this.selected.pet_hamster;
      this.selected.pet_iguana = !!this.selected.pet_iguana;
      this.selected.pet_parrot = !!this.selected.pet_parrot;
      this.selected.pet_snake = !!this.selected.pet_snake;
      this.selected.pet_turtle = !!this.selected.pet_turtle;
      this.selected.pet_rabbit = !!this.selected.pet_rabbit;
    },
    /**
     * отправить новые данные о своем профиле на сервер
     */
    sendData() {
      this.$http
        .post('v1/profile/edit-about-me', {
          access_token: window.localStorage['access-token'],
          religion: this.selected.religion ? this.selected.religion.k : '',
          profession: this.selected.profession,
          education: this.selected.education ? this.selected.education.k : '',
          smoke: this.selected.smoke ? this.selected.smoke.k : '',
          drink: this.selected.drink ? this.selected.drink.k : '',
          children: this.selected.children ? this.selected.children.k : '',
          interests: this.selected.interests,
          pet_aquarium_fish: +this.selected.pet_aquarium_fish,
          pet_cat: +this.selected.pet_cat,
          pet_chinchilla: +this.selected.pet_chinchilla,
          pet_dog: +this.selected.pet_dog,
          pet_hamster: +this.selected.pet_hamster,
          pet_iguana: +this.selected.pet_iguana,
          pet_parrot: +this.selected.pet_parrot,
          pet_snake: +this.selected.pet_snake,
          pet_turtle: +this.selected.pet_turtle,
          pet_rabbit: +this.selected.pet_rabbit,
          celebration_birthday: +this.selected.celebration_birthday,
          celebration_christmas_day: +this.selected.celebration_christmas_day,
          celebration_easter: +this.selected.celebration_easter,
          celebration_halloween: +this.selected.celebration_halloween,
          celebration_new_years_day: +this.selected.celebration_new_years_day,
          celebration_valentines_day: +this.selected.celebration_valentines_day,
          celebration_womens_day: +this.selected.celebration_womens_day,
          target_family: +this.selected.target_family,
          target_meeting: +this.selected.target_meeting,
          target_children: +this.selected.target_children,
          target_communication: +this.selected.target_communication,
          target_flirtation: +this.selected.target_flirtation,
          target_friendship: +this.selected.target_friendship,
          target_journey: +this.selected.target_journey,
          accommodation: this.selected.accommodation ? this.selected.accommodation.k : '',
          about_me: this.selected.about_me,
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$store.commit('addAlert', {
                type: 'success',
                text: this.$t('alerts.settingsSuccess'),
              });
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
          }
        );
    },
    /**
     * запустить валидацию формы и в случае успеха отправить запрос на сохранение
     *
     * @param scope {string} - область видимости для плагина с валидацией
     */
    submitForm(scope) {
      this.$validator
        .validateAll(scope)
        .then((response) => {
          if (response) {
            this.sendData();
          }
        })
        .catch(() => this.$scrollTo(this.$refs.form, 1000, { offset: -50 }));
    },
  },
};
</script>

<style scoped lang="scss">
textarea {
  min-height: 150px;
}
</style>
