<template>
  <div class="d-flex flex-wrap multiple-row">
    <div class="date-select">
      <multiselect
        v-model="day"
        :allow-empty="true"
        :max-height="maxHeightSelect"
        :options="days"
        :searchable="false"
        :show-labels="false"
        label="value"
        track-by="key"
      />

      <!--			<select class="form-control" v-model="day">
              <option v-for="i in days" :value="i.key" :key="i.key" :disabled="i.disabled">{{i.value}}</option>
            </select>-->
    </div>
    <div class="date-select date-select-month">
      <multiselect
        v-model="month"
        :max-height="maxHeightSelect"
        :options="months"
        :searchable="false"
        :show-labels="false"
        label="value"
        track-by="key"
      />

      <!--			<select class="form-control" v-model="month">
              <option v-for="i in months" :value="i.key" :key="i.key" :disabled="i.disabled">{{i.value}}</option>
            </select>-->
    </div>
    <div class="date-select">
      <multiselect
        v-model="year"
        :max-height="maxHeightSelect"
        :options="years"
        :searchable="false"
        :show-labels="false"
        label="value"
        track-by="key"
      />
      <!--			<select class="form-control" v-model="year">
              <option v-for="i in years" :value="i.key" :key="i.key" :disabled="i.disabled">{{i.value}}</option>
            </select>-->
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  components: {},
  props: {
    minAge: {
      type: Number,
      required: true,
    },
    maxAge: {
      type: Number,
      required: true,
    },
    /* value: {
       type: Object,
       required: true
       }, */
    value: [String, Number],
    date: [String, Number],
    maxHeightSelect: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      localVal: '',
      day: { key: '0', value: 'Day', $isDisabled: false },
      month: { key: '0', value: 'Month', $isDisabled: false },
      year: { key: '0', value: 'Year', $isDisabled: false },
      months: [
        { key: '0', value: 'Month', $isDisabled: false },
        { key: '1', value: 'January', $isDisabled: false },
        { key: '2', value: 'February', $isDisabled: false },
        { key: '3', value: 'March', $isDisabled: false },
        { key: '4', value: 'April', $isDisabled: false },
        { key: '5', value: 'May', $isDisabled: false },
        { key: '6', value: 'June', $isDisabled: false },
        { key: '7', value: 'July', $isDisabled: false },
        { key: '8', value: 'August', $isDisabled: false },
        { key: '9', value: 'September', $isDisabled: false },
        { key: '10', value: 'October', $isDisabled: false },
        { key: '11', value: 'November', $isDisabled: false },
        { key: '12', value: 'December', $isDisabled: false },
      ],
      days: [],
      years: [],
    };
  },
  computed: {
    /**
     * Получить или записать локальную переменную с значением
     */
    val: {
      get() {
        return this.localVal;
      },
      set(v) {
        this.localVal = v;
      },
    },

    /**
     * Получить окончательный вариант даты
     * @return {number}
     */
    // finalDates() {
    // 	let tmp = moment().set( {
    // 		'year': this.year.key,
    // 		'month': this.month.key - 1,
    // 		'date': this.day.key
    // 	} );
    // 	console.log(tmp)
    // 	return +moment( tmp ); //m
    // }
  },
  watch: {
    /**
     * Отслеживать изменения дня и запускать соответствующие функции
     */
    day(v, o) {
      if (v !== o) {
        if (!v) {
          this.day = { key: '0', value: 'Day', $isDisabled: false };
          this.watchDay('0');
        } else {
          this.watchDay(v.key);
          this.checkLeapYear(o ? o.key : '0');
        }
      }
      this.finalDate();
    },
    /**
     * Отслеживать изменения месяца и запускать соответствующие функции
     */
    month(v, o) {
      if (v !== o) {
        if (!v) {
          this.month = { key: '0', value: 'Month', $isDisabled: false };
          this.watchMonth('0');
          this.checkLeapYear();
        } else {
          this.watchMonth(v.key);
          this.checkLeapYear();
        }
        this.finalDate();
      }
    },
    /**
     * Отслеживать изменения года и запускать соответствующие функции
     */
    year(v) {
      if (!v) {
        this.year = { key: '0', value: 'Year', $isDisabled: false };
        this.watchYear('0');
        this.checkLeapYear();
      } else {
        this.watchYear(v.key);
        this.finalDate();
      }
    },
    /**
     * Передавать в родителя ввод даты
     */

    /**
     * Получить порядковый номер дня/месяца/года
     */
    val(v) {
      if (v) {
        this.day = moment(v).get('date');
        this.month = moment(v).get('month') + 1;
        this.year = moment(v).get('year');
      }
    },
    date(v, o) {
      if (v !== o) {
        this.dayBirth = moment(this.date).format('D');
        this.monthBirth = moment(this.date).format('M');
        this.yearBirth = moment(this.date).format('YYYY');
        const month = this.months.find((i) => i.key === this.monthBirth);
        this.day = { key: +this.dayBirth, value: this.dayBirth, $isDisabled: false };
        this.month = month;
        this.year = { key: +this.yearBirth, value: +this.yearBirth, $isDisabled: false };
      }
    },
  },
  mounted() {
    this.localVal = this.value;
    this.generateDays();
    this.generateYears();
    if (this.date) {
      this.dayBirth = moment(this.date).format('D');
      this.monthBirth = moment(this.date).format('M');
      this.yearBirth = moment(this.date).format('YYYY');
      const month = this.months.find((i) => i.key === this.monthBirth);
      this.day = { key: +this.dayBirth, value: this.dayBirth, $isDisabled: false };
      this.month = month;
      this.year = { key: +this.yearBirth, value: +this.yearBirth, $isDisabled: false };
    }
  },
  beforeUpdate() {
    this.localVal = this.value;
  },
  methods: {
    finalDate() {
      const tmp = moment().set({
        year: this.year.key,
        month: this.month.key - 1,
        date: this.day.key,
      });
      const day = +this.day.key;
      const month = +this.month.key;
      const year = +this.year.key;
      if ((day && month && year) || (!day && !month && !year)) {
        if (!day && !month && !year) {
          this.$emit('change', null);
        } else {
          this.$emit('change', +moment(tmp));
        }

        this.$emit('incorrect', false);
      } else {
        this.$emit('incorrect', true);
      }
    },

    /**
     * Сгенерировать варианты с днями
     */
    generateDays() {
      const days = [{ key: '0', value: 'Day', $isDisabled: false }];
      for (let i = 1; i <= 31; i++) {
        days.push({
          key: i,
          value: i,
          $isDisabled: false,
        });
      }
      this.days = days;
    },

    /**
     * Сгенерировать варианты с годами
     */
    generateYears() {
      const year = new Date().getFullYear();
      const start = year - this.minAge;
      const count = start - this.maxAge;
      const years = [{ key: '0', value: 'Year', disabled: false }];

      for (let i = start; i >= count; i--) {
        years.push({
          key: i,
          value: i,
          $isDisabled: false,
        });
      }

      this.years = years;
    },

    /**
     * Валидация выбора дня (29, 30, 31 числа)
     *
     * @param v {string | number}
     */
    watchDay(v) {
      this.months.forEach((i) => (i.$isDisabled = false)); // set all active

      if (+v === 29) {
        /* let a = moment( [ +this.year ] ).isLeapYear();
          debugger */
        if (!moment([+this.year.key]).isLeapYear()) {
          const febr = this.months.find((i) => +i.key === 2);
          febr.$isDisabled = true;
        }
      }

      if (+v === 30) {
        const february = this.months.find((i) => +i.key === 2); // find february
        february.$isDisabled = true;
        if (this.month.key === '2') this.month.key = '0'; // clear selection
      } else if (+v === 31) {
        this.months.forEach((i) => {
          if (i.key === '4' || i.key === '6' || i.key === '11' || i.key === '2' || i.key === '9') {
            i.$isDisabled = true;
          }
          if (
            this.month.key === '4' ||
            this.month.key === '6' ||
            this.month.key === '11' ||
            this.month.key === '2' ||
            this.month.key === '9'
          ) {
            this.month.key = '0'; // clear selection
          }
        }, this);
      }
    },

    /**
     * Валидация выбора месяца с учетом высокосного года и отключение дат в исключительных ситуациях
     *
     * @param v {string | number}
     */
    watchMonth(v) {
      this.years.forEach((i) => {
        i.$isDisabled = false;
      }); // set all active

      this.days.forEach((i) => {
        i.$isDisabled = false;
      }); // set all active

      switch (+v) {
        case 4:
        case 6:
        case 9:
        case 11:
          // eslint-disable-next-line no-case-declarations
          const day = this.days.find((i) => i.key === 31);
          day.$isDisabled = true;
          break;
        case 2:
          // eslint-disable-next-line no-case-declarations
          const days = this.days.filter((i) => +i.key >= 30);
          days.forEach((i) => {
            i.$isDisabled = true;
          });
          break;
      }

      if (+v === 2 && +this.day === 29) {
        if (+this.year.key) {
          if (!moment([this.year.key]).isLeapYear()) {
            this.year.key = '0'; // reset year if 29th Feb of non-leap year
          }
        }
      } else if (+v === 2 && !moment([this.year.key]).isLeapYear()) {
        const day = this.days.find((i) => +i.key === 29);
        day.$isDisabled = true;
      }
    },

    /**
     * Валидация выбора года
     *
     * @param v {string | number}
     */
    watchYear(v) {
      const day = this.days.find((i) => +i.key === 29);
      const febr = this.months.find((i) => +i.key === 2);
      if (!moment([+v]).isLeapYear() && +this.month.key === 2) {
        day.$isDisabled = true;
      } else {
        day.$isDisabled = false;
      }
      if (moment([+v]).isLeapYear() && this.day.key <= 29) {
        febr.$isDisabled = false;
      } else if (this.day.key < 29) {
        febr.$isDisabled = false;
      } else {
        febr.$isDisabled = true;
      }
    },

    /**
     * Проверяет, высокосный ли выбранный год
     * @param old {string | number}
     */
    checkLeapYear(old) {
      if (+this.day.key === 29 && +this.month.key === 2) {
        this.years.forEach((i) => {
          if (!moment([i.key]).isLeapYear()) {
            i.$isDisabled = true;
          }
        });
      } else if (+old === 29 && +this.month.key === 2) {
        this.years.forEach((i) => (i.$isDisabled = false));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.date-select {
  min-width: 80px;

  &:nth-child(even) {
    margin-right: 15px;
    margin-left: 15px;
    min-width: 120px;
  }
}

@media screen and (max-width: 500px) {
  .date-select {
    width: 100%;

    &:nth-child(even) {
      margin: 10px 0 10px;
      min-width: 120px;
    }
  }
}
</style>
