<template>
  <div v-if="sex.isMan" class="main-block settings-wrap">
    <div class="edit-profile white-box">
      <div class="row">
        <div class="heading-response d-lg-none">
          <h3 class="header-title" @click="dropdown = !dropdown">
            {{ $t('profile.editProfile') }}
          </h3>
          <transition name="slide-fade">
            <ul
              v-show="dropdown"
              id="responsive-nav-tab"
              :class="{ open: dropdown }"
              role="tablist"
              class="tab-list"
            >
              <div class="imitate" @click="dropdown = !dropdown" />
              <router-link exact tag="li" :to="{ name: 'edit' }">
                <a><span class="rbi rbi-info" />{{ $t('profile.mainEdit') }}</a>
              </router-link>
              <router-link exact tag="li" :to="{ name: 'edit-about' }">
                <a><span class="rbi rbi-male" />{{ $t('profile.aboutMe') }}</a>
              </router-link>
              <router-link exact tag="li" :to="{ name: 'edit-appearance' }">
                <a><span class="rbi rbi-lists" />{{ $t('profile.appearance') }}</a>
              </router-link>
              <router-link exact tag="li" :to="{ name: 'edit-prefers' }">
                <a><span class="rbi rbi-female" />{{ $t('profile.perfWoman') }}</a>
              </router-link>
            </ul>
          </transition>
        </div>
        <div class="tab-box d-none d-lg-block col-3">
          <h3 class="header-title hidden-xs">
            {{ $t('profile.editProfile') }}
          </h3>
          <ul id="desktop-nav-tab" role="tablist" class="tab-list">
            <div class="imitate" @click="dropdown = !dropdown" />
            <router-link exact tag="li" :to="{ name: 'edit' }">
              <a><span class="rbi rbi-info" />{{ $t('profile.mainEdit') }}</a>
            </router-link>
            <router-link exact tag="li" :to="{ name: 'edit-about' }">
              <a><span class="rbi rbi-male" />{{ $t('profile.aboutMe') }}</a>
            </router-link>
            <router-link exact tag="li" :to="{ name: 'edit-appearance' }">
              <a><span class="rbi rbi-lists" />{{ $t('profile.appearance') }}</a>
            </router-link>
            <router-link exact tag="li" :to="{ name: 'edit-prefers' }">
              <a><span class="rbi rbi-female" />{{ $t('profile.perfWoman') }}</a>
            </router-link>
          </ul>
        </div>
        <div class="col-md-12 col-lg-9">
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dropdown: false,
    };
  },
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    /**
     * запуск проверки запрета на вход при получении профиля
     */
    sex() {
      this.preventEnter();
    },
    /**
     * при переходе между вкладками закрываем дропдаун
     */
    $route() {
      this.dropdown = false;
    },
  },
  created() {
    this.preventEnter();
  },
  methods: {
    /**
     * запрет входа на страницу девушке
     */
    preventEnter() {
      if (this.sex.isWoman) {
        this.$router.push({
          name: '404',
        });
      }
    },
  },
};
</script>

<style>
.slide-enter-active {
  animation: slide-in 200ms ease-out forwards;
}

.slide-leave-active {
  animation: slide-out 200ms ease-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-30px);
    opacity: 0;
  }
}
</style>
