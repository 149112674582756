<template>
  <div class="settings-box white-box">
    <div class="settings-box__form">
      <h3 class="tab-heading">
        {{ $t('profile.perfWoman') }}
      </h3>
      <form
        ref="form"
        data-vv-scope="prefer"
        class="form-horizontal"
        @submit.prevent="submitForm('prefer')"
      >
        <div class="form-group" :class="{ 'has-error': validateAge }">
          <div class="row">
            <label class="form-group--label col-sm-2" for="m-a">{{ $t('profile.ageFrom') }}:</label>
            <div class="form-group--input col-sm-10">
              <div class="row">
                <div class="form-group--input col-sm-2 col-xs-4">
                  <input
                    id="m-a"
                    v-model="ageFrom"
                    type="number"
                    class="form-control transparent w-full"
                  />
                </div>
                <div class="col-xs-7 col-sm-4">
                  <div class="row">
                    <label class="form-group--label col-sm-2" for="ma-a"
                      >{{ $t('profile.to') }}:</label
                    >
                    <div class="form-group--input col-sm-7 col-xs-9">
                      <input
                        id="ma-a"
                        v-model="ageTo"
                        type="number"
                        class="form-control transparent w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="validateAge" class="error">
                <i class="fa fa-warning is-danger" />
                <span class="is-danger">{{ $t('profile.errors.failAge') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label for="hair" class="form-group--label col-sm-2"
              >{{ $t('profile.hairColor') }}:</label
            >
            <div class="form-group--input col-sm-10">
              <multiselect
                id="hair"
                v-model="selected.about_woman_hair_color"
                :allow-empty="false"
                :options="values.hair"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2">{{ $t('profile.eyesColor') }}:</label>
            <div class="form-group--input col-sm-10">
              <multiselect
                id="eyes"
                v-model="selected.about_woman_eye_color"
                :allow-empty="false"
                :options="values.eyes"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2">{{ $t('profile.bodyType') }}:</label>
            <div class="form-group--input col-sm-10">
              <multiselect
                id="bd"
                v-model="selected.about_woman_body"
                :allow-empty="false"
                :options="values.body"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': validateCompareHeight }">
          <div class="row">
            <label for="h-1" class="form-group--label col-sm-2"
              >{{ $t('profile.heightFrom') }}:</label
            >
            <div class="form-group--input col-sm-10">
              <div class="row">
                <div class="col-sm-6 col-xs-12">
                  <div class="set-flex multiple-row-select">
                    <div class="pref-height">
                      <multiselect
                        id="h-1"
                        v-model="heightFrom.feet"
                        :allow-empty="false"
                        :options="values.feet"
                        :searchable="false"
                        track-by="k"
                        label="v"
                        :show-labels="false"
                      />
                      <!--<select id="h-1" v-model="heightFrom.feet" class="form-control transparent w-full">-->
                      <!--<option v-for="i in values.feet" :value="i.k">{{i.v}}</option>-->
                      <!--</select>-->
                    </div>
                    <div class="pref-height">
                      <multiselect
                        v-model="heightFrom.inches"
                        :allow-empty="false"
                        :options="values.inches"
                        :searchable="false"
                        track-by="k"
                        label="v"
                        :show-labels="false"
                      />
                      <!--<select class="form-control transparent w-full" v-model="heightFrom.inches">-->
                      <!--<option v-for="i in values.inches" :value="i.k">{{i.v}}</option>-->
                      <!--</select>-->
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-xs-12">
                  <div class="row">
                    <label for="h-2" class="form-group--label col-sm-2"
                      >{{ $t('profile.to') }}:</label
                    >
                    <div class="set-flex multiple-row-select col-sm-10">
                      <div class="pref-height">
                        <multiselect
                          id="h-2"
                          v-model="heightTo.feet"
                          :allow-empty="false"
                          :options="values.feet"
                          :searchable="false"
                          track-by="k"
                          label="v"
                          :show-labels="false"
                        />
                      </div>
                      <div class="pref-height">
                        <multiselect
                          v-model="heightTo.inches"
                          :allow-empty="false"
                          :options="values.inches"
                          :searchable="false"
                          track-by="k"
                          label="v"
                          :show-labels="false"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="validateCompareHeight" class="error">
                <i class="fa fa-warning is-danger" />
                <span class="is-danger">{{ $t('profile.errors.failHeight2') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': validateWeight }">
          <div class="row">
            <label for="w-1" class="form-group--label col-sm-2"
              >{{ $t('profile.weightFrom') }}:</label
            >
            <div class="form-group--input col-sm-10">
              <div class="row">
                <div class="col-sm-5 col-xs-12">
                  <div class="input-group">
                    <input
                      id="w-1"
                      v-model="weightFrom"
                      type="number"
                      class="form-control transparent w-full"
                    />
                    <div class="input-group-addon">
                      {{ $t('profile.lbs') }}
                    </div>
                  </div>
                </div>
                <div class="col-sm-7">
                  <div class="row">
                    <label for="w-2" class="form-group--label col-sm-2"
                      >{{ $t('profile.to') }}:</label
                    >
                    <div class="form-group--input col-sm-10">
                      <div class="input-group">
                        <input
                          id="w-2"
                          v-model="weightTo"
                          type="number"
                          class="form-control transparent w-full"
                        />
                        <div class="input-group-addon">
                          {{ $t('profile.lbs') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="validateWeight" class="error">
                <i class="fa fa-warning is-danger" />
                <span class="is-danger">{{ $t('profile.errors.failWeight') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label for="eng" class="form-group--label col-sm-2">{{ $t('profile.lvlEng') }}:</label>
            <div class="form-group--input col-sm-10">
              <multiselect
                id="eng"
                v-model="selected.about_woman_english_level"
                :allow-empty="false"
                :options="values.eng"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label for="child" class="form-group--label col-sm-2"
              >{{ $t('profile.children') }}:</label
            >
            <div class="form-group--input col-sm-10">
              <multiselect
                id="child"
                v-model="selected.about_woman_children"
                :allow-empty="false"
                :options="values.children"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.first('prefer.type') }">
          <div class="row">
            <label for="type" class="form-group--label col-sm-2"
              >{{ $t('profile.womanType') }}:</label
            >
            <div class="form-group--input col-sm-10">
              <textarea
                id="type"
                v-model="selected.about_woman_text"
                v-validate="'max:400'"
                name="type"
                data-vv-as="Type of woman"
                class="form-control transparent w-full"
              />
              <div v-show="errors.has('prefer.type')" class="error">
                <i class="fa fa-warning is-danger" />
                <span class="is-danger">{{ errors.first('prefer.type') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2" />
          <div class="col-sm-10">
            <rb-button class="btn-black" type="submit">
              {{ $t('profile.save') }}
            </rb-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { MEASURE_CALCULATIONS } from '../../../../mixins/types/profile';
import RbButton from '../../../Button';

export default {
  components: {
    RbButton,
  },
  mixins: [MEASURE_CALCULATIONS],
  data() {
    return {
      utils: {
        merged: false,
      },
      values: {
        feet: [
          { k: 'no', v: this.$t('profile.vFeet.no') },
          { k: 4, v: this.$t('profile.vFeet.v', ['4']) },
          { k: 5, v: this.$t('profile.vFeet.v', ['5']) },
          { k: 6, v: this.$t('profile.vFeet.v', ['6']) },
        ],
        inches: [
          { k: 'no', v: this.$t('profile.vInches.no') },
          { k: 0, v: this.$t('profile.vInches.v', ['0']) },
          { k: 1, v: this.$t('profile.vInches.v', ['1']) },
          { k: 2, v: this.$t('profile.vInches.v', ['2']) },
          { k: 3, v: this.$t('profile.vInches.v', ['3']) },
          { k: 4, v: this.$t('profile.vInches.v', ['4']) },
          { k: 5, v: this.$t('profile.vInches.v', ['5']) },
          { k: 6, v: this.$t('profile.vInches.v', ['6']) },
          { k: 7, v: this.$t('profile.vInches.v', ['7']) },
          { k: 8, v: this.$t('profile.vInches.v', ['8']) },
          { k: 9, v: this.$t('profile.vInches.v', ['9']) },
          { k: 10, v: this.$t('profile.vInches.v', ['10']) },
          { k: 11, v: this.$t('profile.vInches.v', ['11']) },
        ],
        eyes: [
          { k: 'no', v: this.$t('profile.vEyes.no') },
          { k: 'black', v: this.$t('profile.vEyes.black') },
          { k: 'green', v: this.$t('profile.vEyes.green') },
          { k: 'gray', v: this.$t('profile.vEyes.gray') },
          { k: 'brown', v: this.$t('profile.vEyes.brown') },
          { k: 'light-brown', v: this.$t('profile.vEyes.lightBrown') },
          { k: 'blue', v: this.$t('profile.vEyes.blue') },
        ],
        hair: [
          { k: 'no', v: this.$t('profile.vHair.no') },
          { k: 'blonde', v: this.$t('profile.vHair.blonde') },
          { k: 'brown', v: this.$t('profile.vHair.brown') },
          { k: 'redhead', v: this.$t('profile.vHair.redhead') },
          { k: 'brunette', v: this.$t('profile.vHair.brunette') },
          { k: 'colored', v: this.$t('profile.vHair.colored') },
        ],
        body: [
          { k: 'no', v: this.$t('profile.vBody.no') },
          { k: 'thin', v: this.$t('profile.vBody.thin') },
          { k: 'slender', v: this.$t('profile.vBody.slender') },
          { k: 'athletic', v: this.$t('profile.vBody.athletic') },
          { k: 'a few extra pounds', v: this.$t('profile.vBody.extra') },
          { k: 'plump', v: this.$t('profile.vBody.plump') },
        ],
        eng: [
          { k: 'no', v: this.$t('profile.vLvlLangs.no') },
          { k: 'basic', v: this.$t('profile.vLvlLangs.basic') },
          { k: 'intermediate', v: this.$t('profile.vLvlLangs.intermediate') },
          { k: 'good', v: this.$t('profile.vLvlLangs.good') },
          { k: 'fluent', v: this.$t('profile.vLvlLangs.fluent') },
        ],
        children: [
          { k: 'no', v: this.$t('profile.vChildren.no') },
          { k: 'I have no children', v: this.$t('profile.vChildren.not') },
          { k: 'no but I want to have children', v: this.$t('profile.vChildren.want') },
          { k: 'have children', v: this.$t('profile.vChildren.have') },
          { k: 'have children, but living separately', v: this.$t('profile.vChildren.haveSep') },
          { k: 'have adult children', v: this.$t('profile.vChildren.adult') },
        ],
      },
      selected: {
        about_woman_age_from: 0,
        about_woman_age_to: 0,
        about_woman_hair_color: '',
        about_woman_eye_color: '',
        about_woman_body: '',
        about_woman_growth_from: 0,
        about_woman_growth_to: 0,
        about_woman_weight_from: 0,
        about_woman_weight_to: 0,
        about_woman_english_level: '',
        about_woman_children: '',
        about_woman_text: '',
      },
      heightFrom: {
        feet: 0,
        inches: 0,
      },
      heightTo: {
        feet: 0,
        inches: 0,
      },
    };
  },
  computed: {
    ageFrom: {
      get() {
        return this.selected.about_woman_age_from || 0;
      },
      set(v) {
        this.selected.about_woman_age_from = v;
      },
    },
    ageTo: {
      get() {
        return this.selected.about_woman_age_to || 0;
      },
      set(v) {
        this.selected.about_woman_age_to = v;
      },
    },
    validateAge() {
      const from = +this.selected.about_woman_age_from;
      const to = +this.selected.about_woman_age_to;
      if ((!from && !to) || (from >= 18 && to <= 100 && from <= to)) {
        return false;
      }
      return true;
    },
    validateCompareHeight() {
      return (
        this.validateHeight(this.feetFrom, this.inchesFrom) ||
        this.validateHeight(this.feetTo, this.inchesTo) ||
        this.cmHeightTo < this.cmHeightFrom ||
        (this.feetFrom === 'no' && this.cmHeightTo > 0)
      );
    },
    validateWeight() {
      const from = this.selected.about_woman_weight_from;
      const to = this.selected.about_woman_weight_to;
      if ((!from && !to) || (from >= 0 && from <= to)) {
        return false;
      }
      return true;
    },
    user() {
      return this.$store.getters.user;
    },
    weightFrom: {
      get() {
        return Math.round(this.kgToLbs(this.selected.about_woman_weight_from));
      },
      set(v) {
        this.selected.about_woman_weight_from = this.lbsToKg(v);
      },
    },
    weightTo: {
      get() {
        return Math.round(this.kgToLbs(this.selected.about_woman_weight_to));
      },
      set(v) {
        this.selected.about_woman_weight_to = this.lbsToKg(v);
      },
    },
    cmHeightFrom() {
      return this.feetInchToCm(this.heightFrom.feet.k, this.heightFrom.inches.k);
    },
    cmHeightTo() {
      return this.feetInchToCm(this.heightTo.feet.k, this.heightTo.inches.k);
    },
    feetFrom() {
      return this.heightFrom.feet;
    },
    inchesFrom() {
      return this.heightFrom.inches;
    },
    feetTo() {
      return this.heightTo.feet;
    },
    inchesTo() {
      return this.heightTo.inches;
    },
  },
  watch: {
    /**
     * при получении данных своего профиля начинаем загружать данные в компонент
     */
  },
  mounted() {
    document.title = this.$t('profile.perfWoman');
    setTimeout(() => {
      this.initParams();
      this.$forceUpdate();
    }, 300);
  },
  methods: {
    /**
     * валидация данных перед отправкой. В случае успеха - отправляются данные
     * в случае провала происходит скролл наверх
     *
     * @param scope {string} область видимости для плагина с валидацией
     */
    submitForm(scope) {
      this.$validator
        .validateAll(scope)
        .then((response) => {
          if (
            response &&
            !this.validateCompareHeight &&
            !this.validateAge &&
            !this.validateCompareHeight
          ) {
            this.sendData();
          } else {
            this.$scrollTo(this.$refs.form, 1000, { offset: -50 });
          }
        })
        .catch(() => this.$scrollTo(this.$refs.form, 1000, { offset: -50 }));
    },
    /**
     * отправка данных на сервер
     */
    sendData() {
      this.$http
        .post('v1/profile/edit-perfect-woman', {
          access_token: window.localStorage['access-token'],
          about_woman_age_from: this.selected.about_woman_age_from,
          about_woman_age_to: this.selected.about_woman_age_to,
          about_woman_hair_color: this.selected.about_woman_hair_color
            ? this.selected.about_woman_hair_color.k
            : '',
          about_woman_eye_color: this.selected.about_woman_eye_color
            ? this.selected.about_woman_eye_color.k
            : '',
          about_woman_body: this.selected.about_woman_body ? this.selected.about_woman_body.k : '',
          about_woman_growth_from: this.feetFrom === 'no' ? 0 : this.cmHeightFrom,
          about_woman_growth_to: this.feetTo === 'no' ? 0 : this.cmHeightTo,
          about_woman_weight_from: this.selected.about_woman_weight_from,
          about_woman_weight_to: this.selected.about_woman_weight_to,
          about_woman_english_level: this.selected.about_woman_english_level
            ? this.selected.about_woman_english_level.k
            : '',
          about_woman_children: this.selected.about_woman_children
            ? this.selected.about_woman_children.k
            : '',
          about_woman_text: this.selected.about_woman_text,
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$store.commit('addAlert', {
                type: 'success',
                text: this.$t('alerts.settingsSuccess'),
              });
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
          }
        );
    },
    /**
     * загрузка данных профиля в компонент
     */
    initParams() {
      if (!this.utils.merged) {
        for (const key in this.selected) {
          this.selected[key] = this.user.profile_additional_info[key];
        }
        if (
          this.selected.about_woman_growth_from === 0 ||
          this.selected.about_woman_growth_from === null
        ) {
          this.heightFrom = {
            feet: 'no',
            inches: 'no',
          };
        } else {
          this.heightFrom = {
            feet: this.cmToFeetInch(this.selected.about_woman_growth_from).feet,
            inches: this.cmToFeetInch(this.selected.about_woman_growth_from).inches,
          };
        }
        if (
          this.selected.about_woman_growth_to === 0 ||
          this.selected.about_woman_growth_to === null
        ) {
          this.heightTo = {
            feet: 'no',
            inches: 'no',
          };
        } else {
          this.heightTo = {
            feet: this.cmToFeetInch(this.selected.about_woman_growth_to).feet,
            inches: this.cmToFeetInch(this.selected.about_woman_growth_to).inches,
          };
        }
        this.utils.merged = true;
      }

      this.selected.about_woman_hair_color = this.values.hair.find(
        (i) => i.k === this.selected.about_woman_hair_color
      );
      this.selected.about_woman_eye_color = this.values.eyes.find(
        (i) => i.k === this.selected.about_woman_eye_color
      );
      this.selected.about_woman_body = this.values.body.find(
        (i) => i.k === this.selected.about_woman_body
      );
      this.selected.about_woman_english_level = this.values.eng.find(
        (i) => i.k === this.selected.about_woman_english_level
      );
      this.selected.about_woman_children = this.values.children.find(
        (i) => i.k === this.selected.about_woman_children
      );

      this.heightFrom.feet = this.values.feet.find(
        (i) =>
          i.k ===
          (this.selected.about_woman_growth_from === 0 ||
          this.selected.about_woman_growth_from === null
            ? 'no'
            : this.cmToFeetInch(this.selected.about_woman_growth_from).feet)
      );
      this.heightFrom.inches = this.values.inches.find(
        (i) =>
          i.k ===
          (this.selected.about_woman_growth_from === 0 ||
          this.selected.about_woman_growth_from === null
            ? 'no'
            : this.cmToFeetInch(this.selected.about_woman_growth_from).inches)
      );
      console.log(this.selected.about_woman_growth_from);
      this.heightTo.feet = this.values.feet.find(
        (i) =>
          i.k ===
          (this.selected.about_woman_growth_to === 0 || this.selected.about_woman_growth_to === null
            ? 'no'
            : this.cmToFeetInch(this.selected.about_woman_growth_to).feet)
      );
      this.heightTo.inches = this.values.inches.find(
        (i) =>
          i.k ===
          (this.selected.about_woman_growth_to === 0 || this.selected.about_woman_growth_to === null
            ? 'no'
            : this.cmToFeetInch(this.selected.about_woman_growth_to).inches)
      );
    },
    /**
     * валидация роста
     *
     * @param feet {number} футы
     * @param inches {number} дюймы
     * @return {boolean}
     */
    validateHeight(feet, inches) {
      return (inches === 'no' && feet !== 'no') || (feet === 'no' && inches !== 'no');
    },
  },
};
</script>

<style scoped lang="scss">
textarea {
  min-height: 150px;
}
</style>
