<template>
  <div class="settings-box white-box">
    <div class="settings-box__form">
      <h2 class="tab-heading">
        {{ $t('profile.mainEdit') }}
      </h2>
      <form
        data-vv-scope="mainEdit"
        class="form-horizontal"
        @submit.prevent="submitForm('mainEdit')"
      >
        <div class="form-group" :class="{ 'has-error': errors.first('mainEdit.name') }">
          <div class="row">
            <label class="form-group--label col-sm-2" for="name">{{ $t('profile.name') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <input
                id="name"
                ref="inputName"
                v-model="selected.name"
                v-validate="'required|alpha_spaces|max:40'"
                data-vv-as="Name"
                type="text"
                name="name"
                value="userName"
                class="form-control"
              />
              <div v-show="errors.has('mainEdit.name')" class="error">
                <i class="fa fa-warning is-danger" />
                <span class="is-danger">{{ errors.first('mainEdit.name') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': incorrectCountry }">
          <div class="row">
            <label class="form-group--label col-sm-2">{{ $t('profile.country') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                v-model="countryName"
                :options="formattedCountries"
                :show-labels="false"
                :placeholder="$t('profile.no')"
                @close="searchCity"
              />
              <div v-show="incorrectCountry" class="error">
                <i class="fa fa-warning is-danger" />
                <span class="is-danger">{{ $t('profile.errors.failCountry') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': incorrectCity }">
          <div class="row">
            <label class="form-group--label col-sm-2">{{ $t('profile.city') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                v-model="city"
                :options="formattedCities"
                :show-labels="false"
                :placeholder="$t('profile.no')"
                @search-change="searchCity"
              />
            </div>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': validateDate && needValidateDate }">
          <div class="row">
            <label class="form-group--label col-sm-2">{{ $t('profile.dateBirth') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <date-picker
                :min-age="18"
                :max-age="80"
                :date="birth"
                @change="getDate"
                @incorrect="validateDate = $event"
              />
              <div v-show="validateDate && needValidateDate" class="error">
                <i class="fa fa-warning" />
                <span>{{ $t('actionPages.errors.incorrectDateSettings') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2">{{ $t('profile.marital') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                v-model="selected.marital"
                :allow-empty="false"
                :options="values.marital"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2">{{ $t('profile.lvlEng') }}:</label>
            <div class="form-group--input col-xs-12 col-sm-10">
              <multiselect
                v-model="selected.englishSkill"
                :allow-empty="false"
                :options="values.lvlLang"
                :searchable="false"
                track-by="k"
                label="v"
                :show-labels="false"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2 visible-sm visible-md visible-lg"
              >{{ $t('profile.lang') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <div class="row">
                <div class="col-sm-5 col-xs-6">
                  <p class="form-control">
                    {{ $t('profile.vLangs.sp') }}
                  </p>
                </div>
                <label for="lvl-sp" class="col-sm-1 col-xs-1 form-group--label text-right"
                  >{{ $t('profile.vLvlLangs.lvl') }}:</label
                >
                <div class="col-sm-5 col-xs-6">
                  <multiselect
                    id="lvl-sp"
                    v-model="selected.skillSP"
                    :allow-empty="false"
                    :options="values.lvlLangSP"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2 visible-sm visible-md visible-lg"
              >{{ $t('profile.lang') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <div class="row">
                <div class="col-sm-5 col-xs-6">
                  <p class="form-control">
                    {{ $t('profile.vLangs.fr') }}
                  </p>
                </div>
                <label for="lvl-fr" class="col-sm-1 col-xs-1 form-group--label text-right"
                  >{{ $t('profile.vLvlLangs.lvl') }}:</label
                >
                <div class="col-sm-5 col-xs-6">
                  <multiselect
                    id="lvl-fr"
                    v-model="selected.skillFR"
                    :allow-empty="false"
                    :options="values.lvlLangFR"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2 visible-sm visible-md visible-lg"
              >{{ $t('profile.lang') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <div class="row">
                <div class="col-sm-5 col-xs-6">
                  <p class="form-control">
                    {{ $t('profile.vLangs.pt') }}
                  </p>
                </div>
                <label for="lvl-po" class="col-sm-1 col-xs-1 form-group--label text-right"
                  >{{ $t('profile.vLvlLangs.lvl') }}:</label
                >
                <div class="col-sm-5 col-xs-6">
                  <multiselect
                    id="lvl-po"
                    v-model="selected.skillPO"
                    :allow-empty="false"
                    :options="values.lvlLangPO"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="form-group--label col-sm-2 visible-sm visible-md visible-lg"
              >{{ $t('profile.lang') }}:</label
            >
            <div class="form-group--input col-xs-12 col-sm-10">
              <div class="row">
                <div class="col-sm-5 col-xs-6">
                  <p class="form-control">
                    {{ $t('profile.vLangs.ge') }}
                  </p>
                </div>
                <label for="lvl-ge" class="col-sm-1 col-xs-1 form-group--label text-right"
                  >{{ $t('profile.vLvlLangs.lvl') }}:</label
                >
                <div class="col-sm-5 col-xs-6">
                  <multiselect
                    id="lvl-ge"
                    v-model="selected.skillGE"
                    :allow-empty="false"
                    :options="values.lvlLangGE"
                    :searchable="false"
                    track-by="k"
                    label="v"
                    :show-labels="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-2" />
          <div class="col-sm-10">
            <rb-button class="btn-black">
              {{ $t('profile.save') }}
            </rb-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import moment from 'moment';
import DatePicker from '../../../Authorized/parts/DatePicker.vue';
import RbButton from '../../../Button';

export default {
  data() {
    return {
      updateCity: false,
      values: {
        marital: [
          { k: 'no', v: this.$t('profile.vMarital.no') },
          { k: 'single', v: this.$t('profile.vMarital.single') },
          { k: 'never been married', v: this.$t('profile.vMarital.never') },
          { k: 'divorced', v: this.$t('profile.vMarital.divorced') },
          { k: 'widow', v: this.$t('profile.vMarital.widow') },
        ],
        lvlLang: [
          { k: 'no', v: this.$t('profile.vLvlLangs.no') },
          { k: 'basic', v: this.$t('profile.vLvlLangs.basic') },
          { k: 'intermediate', v: this.$t('profile.vLvlLangs.intermediate') },
          { k: 'good', v: this.$t('profile.vLvlLangs.good') },
          { k: 'fluent', v: this.$t('profile.vLvlLangs.fluent') },
        ],
        lvlLangFR: [
          { k: 'no', v: this.$t('profile.vLvlLangs.no') },
          { k: 'basic', v: this.$t('profile.vLvlLangs.basic') },
          { k: 'intermediate', v: this.$t('profile.vLvlLangs.intermediate') },
          { k: 'good', v: this.$t('profile.vLvlLangs.good') },
          { k: 'fluent', v: this.$t('profile.vLvlLangs.fluent') },
        ],
        lvlLangSP: [
          { k: 'no', v: this.$t('profile.vLvlLangs.no') },
          { k: 'basic', v: this.$t('profile.vLvlLangs.basic') },
          { k: 'intermediate', v: this.$t('profile.vLvlLangs.intermediate') },
          { k: 'good', v: this.$t('profile.vLvlLangs.good') },
          { k: 'fluent', v: this.$t('profile.vLvlLangs.fluent') },
        ],
        lvlLangPO: [
          { k: 'no', v: this.$t('profile.vLvlLangs.no') },
          { k: 'basic', v: this.$t('profile.vLvlLangs.basic') },
          { k: 'intermediate', v: this.$t('profile.vLvlLangs.intermediate') },
          { k: 'good', v: this.$t('profile.vLvlLangs.good') },
          { k: 'fluent', v: this.$t('profile.vLvlLangs.fluent') },
        ],
        lvlLangGE: [
          { k: 'no', v: this.$t('profile.vLvlLangs.no') },
          { k: 'basic', v: this.$t('profile.vLvlLangs.basic') },
          { k: 'intermediate', v: this.$t('profile.vLvlLangs.intermediate') },
          { k: 'good', v: this.$t('profile.vLvlLangs.good') },
          { k: 'fluent', v: this.$t('profile.vLvlLangs.fluent') },
        ],
      },
      selected: {
        name: '',
        country: {
          id: 0,
          name: '', // this.$t('profile.no')
        },
        city: '', // this.$t('profile.no'),
        marital: '',
        englishSkill: { k: 'no', v: this.$t('profile.vLvlLangs.no') },
        firstLang: '',
        secondLang: '',
        thirdLang: '',
        fourthLang: '',
        skillSP: { k: 'no', v: this.$t('profile.vLvlLangs.no') },
        skillFR: { k: 'no', v: this.$t('profile.vLvlLangs.no') },
        skillPO: { k: 'no', v: this.$t('profile.vLvlLangs.no') },
        skillGE: { k: 'no', v: this.$t('profile.vLvlLangs.no') },
      },
      incorrectCountry: false,
      incorrectCity: false,
      countries: [],
      cities: [],
      dayBirth: '',
      monthBirth: '',
      yearBirth: '',
      birth: '',
      newBirth: null,
      validateDate: false,
      needValidateDate: false,
      cityPreloader: false,
    };
  },
  methods: {
    initParams() {
      this.selected.marital = this.values.marital.find(
        (i) => i.k === this.user.profile_additional_info.family_status
      );
      this.selected.englishSkill = this.values.lvlLang.find(
        (i) => i.k === this.user.profile_additional_info.lang_english
      );
      this.selected.skillSP = this.values.lvlLang.find(
        (i) => i.k === this.user.profile_additional_info.lang_spanish
      );
      this.selected.skillFR = this.values.lvlLang.find(
        (i) => i.k === this.user.profile_additional_info.lang_french
      );
      this.selected.skillPO = this.values.lvlLang.find(
        (i) => i.k === this.user.profile_additional_info.lang_portuguese
      );
      this.selected.skillGE = this.values.lvlLang.find(
        (i) => i.k === this.user.profile_additional_info.lang_german
      );
    },
    /*
     * установить дату с DataPickerComponent
     * */
    getDate(date) {
      this.newBirth = date;
    },
    /**
     * запуск валидации с проверкой формы. В случае успеха - передаем функцию на сохранение данных
     * в случае провала - поднять скролл наверх
     *
     * @param scope
     */
    submitForm(scope) {
      this.$validator
        .validateAll(scope)
        .then((response) => {
          this.needValidateDate = true;
          if (response && !this.validateDate && !this.incorrectCountry && !this.incorrectCity) {
            this.needValidateDate = false;
            this.submitData();
          } else {
            this.$scrollTo(this.$refs.inputName, 1000, { offset: -50 });
          }
        })
        .catch(() => this.$scrollTo(this.$refs.inputName, 1000, { offset: -50 }));
    },
    /**
     * Выбрать страну
     *
     * @param v {string} навзание города
     * @param searchDummy {boolean} нужно ли заполнять данные "заглушками"
     */
    selectCountry(v, searchDummy) {
      if (v) {
        const record = this.countries.find((i) => {
          if (i.name.toLowerCase() === v.toLowerCase()) {
            return true;
          }
        });
        if (record) {
          this.country.id = record.id;
          if (searchDummy) this.askDummyCity();
        }
      }
    },
    /**
     * Загрузить города, начинающиеся с @param v
     *
     * @param v {string} строка с названием города
     */
    searchCity(v) {
      if (v) {
        this.cityPreloader = true;
        this.$http
          .post('v1/user-guest/city', {
            country_id: this.country.id,
            city_name: v,
          })
          .then(
            (response) => {
              if (response.body.status) this.formattedCities = response.body.result;
              this.cityPreloader = false;
            },
            () => {
              this.cityPreloader = false;
            }
          );
      } else {
        this.searchCity('a');
      }
    },
    /**
     * заполнить список городов заглушками
     */
    askDummyCity() {
      if (!this.formattedCities.length && !this.cityPreloader && this.country.id) {
        if (!this.city) {
          this.searchCity('a');
        }
      }
    },
    /**
     * Сохранить данные о профиле на сервере
     */
    submitData() {
      this.$http
        .post('v1/profile/edit-main', {
          access_token: window.localStorage['access-token'],
          name: this.selected.name,
          //					city: this.city === this.$t('profile.no') || !this.countryName ? '' : this.city,
          city: this.city || '',
          //					country: this.countryName === this.$t('profile.no') || !this.countryName ? '' : this.countryName,
          country: this.countryName || '',
          //					birthday: this.dateBirth || null,
          birthday: this.newBirth ? moment(this.newBirth).unix() : 'null',
          family_status: this.selected.marital ? this.selected.marital.k : '',
          lang_english: this.selected.englishSkill ? this.selected.englishSkill.k : '',
          lang_spanish: this.selected.skillSP ? this.selected.skillSP.k : '',
          lang_french: this.selected.skillFR ? this.selected.skillFR.k : '',
          lang_portuguese: this.selected.skillPO ? this.selected.skillPO.k : '',
          lang_german: this.selected.skillGE ? this.selected.skillGE.k : '',
        })
        .then(
          (r) => {
            if (r.body.status) {
              this.$store.commit('addAlert', {
                type: 'success',
                text: this.$t('alerts.settingsSuccess'),
              });
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.someErr'),
            });
          }
        );
    },
    /**
     * загрузить список стран
     */
    loadCountries() {
      this.$http.post('v1/user-guest/country', {}).then((response) => {
        if (response.body.status) this.formattedCountries = response.body.result;
      });
    },
    /**
     * парсинг даты рождения пользователя
     *
     * @param timestamp {number} таймстемп с датой рождения
     */
    parseUserBirthDate(timestamp) {
      if (timestamp) {
        this.birth = timestamp;
        this.dayBirth = moment(timestamp).format('DD');
        this.monthBirth = moment(timestamp).format('MM');
        this.yearBirth = moment(timestamp).format('YYYY');
      }
    },
    /**
     * загрузка существующего адреса (при получениии данных профиля с сервера)
     */
    loadExistAddress() {
      const self = this;
      setTimeout(() => {
        self.country = {
          //						name: self.user.address_country || this.$t('profile.no'),
          name: self.user.address_country || '',
          id: 0,
        };
        if (self.user.address_city) {
          self.selectCountry(self.countryName);
        } else {
          self.selectCountry(self.countryName, true);
        }

        setTimeout(() => {
          //						self.city = self.user.address_city || this.$t('profile.no');
          self.city = self.user.address_city || '';
          self.updateCity = !self.updateCity;
        }, 0);
      }, 0);
    },
    /**
     * проверить ввод на наличие соответствующей страны
     * @param e {string} - ответ с дочернего компонента
     */
    verifyCountry(e) {
      const record = this.countries.find((i) => i.name === e);
      if (record || !e || e === 'no' || e === this.$t('profile.no')) {
        this.incorrectCountry = false;
      } else {
        this.incorrectCountry = true;
      }
    },
    /**
     * запуск загрузки списка городов с задержкой в 300мс
     *
     * @param v {string} название города
     */
    debSearchCity: debounce(function (v) {
      this.searchCity(v);
    }, 300),
    /**
     * начало загрузки списка городов. Очищает существующие списки, вкл прелоадер
     *
     * @param v {string}
     */
    startCitySearch(v) {
      if (this.country.id) {
        this.cityPreloader = true;
        this.formattedCities = [];
        this.debSearchCity(v);
      } else {
        const self = this;
        setTimeout(() => {
          self.startCitySearch(v);
        }, 100);
      }
    },
  },
  computed: {
    /* validateDate() {
             if ( (this.dayBirth && this.monthBirth && this.yearBirth)
             || (!this.dayBirth && !this.monthBirth && !this.yearBirth) ) {
             return false
             }
             return true;
             }, */
    dateBirth: {
      /* if (this.monthBirth) {
                 let tmp = moment().set( {
                 'year': this.yearBirth,
                 'month': this.monthBirth - 1,
                 'date': this.dayBirth
                 } );
                 return moment( tmp ).unix();
                 } else {
                 return null;
                 } */
      get() {
        return this.date;
      },
      set(v) {
        this.date = v;
      },
    },
    user() {
      return this.$store.getters.user;
    },
    userName() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return (this.selected.name = this.user.name);
    },
    balance() {
      return this.$store.getters.balance;
    },
    formattedCities: {
      get() {
        //					return [ this.$t('profile.no') ].concat( this.cities.map( item => item.name ) )
        return this.cities.map((item) => item.name);
      },
      set(v) {
        this.cities = v;
      },
    },
    formattedCountries: {
      get() {
        if (this.countries.length) {
          //						return [ this.$t('profile.no') ].concat( this.countries.map( item => item.name ) )
          return this.countries.map((item) => item.name);
        }
        //					return [ this.$t('profile.no') ];
        return [];
      },
      set(v) {
        this.countries = v;
      },
    },
    sex() {
      return this.$store.getters.sex;
    },
    countryName: {
      get() {
        return this.selected.country.name; // || this.user.address_country || this.$t('profile.no')
      },
      set(v) {
        this.selected.country.name = v;
      },
    },
    country: {
      get() {
        return this.selected.country;
      },
      set(v) {
        this.selected.country = v;
      },
    },
    city: {
      get() {
        return this.selected.city;
      },
      set(v) {
        this.selected.city = v;
      },
    },
  },
  watch: {
    /**
     * при вводе названия города начинаем загрузку списка городов
     *
     * @param v {string}
     */
    city(v) {
      //				if ( v ) {
      this.startCitySearch(v);
      //				}
      if (v) {
        v.length > 30 ? (this.incorrectCity = true) : (this.incorrectCity = false);
      }
    },
    /**
     *  при вводе названия страны начинаем загрузку списка городов и выбираем страну
     * @param v
     */
    countryName(v) {
      this.selectCountry(v);
      //				v === this.$t('profile.no') ? this.city = v : this.city = '';
      this.cities = [];
      if (!this.city) this.debSearchCity();
      this.city = '';
    },
    /**
     * при получении своего профиля с сервера запускаем поиск города/страны
     * и парсим день рожденья
     */
    sex() {
      this.loadExistAddress();
      this.parseUserBirthDate(this.user.birthday * 1000);
    },
    /**
     * при загрузке стран начинаем выбирать страну
     */
    formattedCountries() {
      this.selectCountry(this.countryName, true);
    },
    /**
     * при изменении дня рождения очищаем валидацию поля дня рождения
     */
    birth() {
      this.needValidateDate = false;
    },
  },
  mounted() {
    this.selected.name = this.user.name;
    document.title = this.$t('profile.mainEdit');
    const self = this;
    /* jQuery( document ).ready( function () {
             jQuery.dobPicker( {
             daySelector: '#dobday',
             monthSelector: '#dobmonth',
             yearSelector: '#dobyear',
             dayDefault: 'Day',
             monthDefault: 'Month',
             yearDefault: 'Year',
             minimumAge: 18,
             maximumAge: 80
             } );
             } ); */
    this.loadCountries();
    setTimeout(() => {
      self.loadExistAddress();
      self.parseUserBirthDate(self.user.birthday * 1000);
      // this.initParams();
      this.$forceUpdate();
    }, 300);
  },
  created() {
    this.initParams();
  },
  components: {
    DatePicker,
    RbButton,
  },
};
</script>

<style lang="scss" scoped="scoped">
.form-control {
  width: 100%;
  background-color: transparent;
}

#dobday {
  option {
    display: none;
  }
}

#dobmonth {
  option {
    display: none;
  }
}

#dobyear {
  option {
    display: none;
  }
}
</style>
